import { PlanType } from "@/components/editor/stripe/Cell";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { checkUserSubscriptionType } from "@/lib/api-account-utils";
import { runtimeEnv } from "@/lib/runtime-utils";
import { checkEnterpriseCompany } from "@/lib/workspace-util";
import { useGetEnvListQuery, useGetEnvQuery } from "@/redux/api/commonApi";
import dashboardApi from "@/redux/api/dashboardApi";
import { getDashBoardState } from "@/redux/store";
import { dashboardActions } from "@/redux/stores/dashboardStore";
import { projectActions } from "@/redux/stores/projectStore";
import axios from "axios";
import { GetServerSidePropsContext } from "next";
import { getServerSession } from "next-auth";
import { AuthTemplate, nextAuthOptions } from "next-auth-account";
import { useSession } from "next-auth/react";
import { useRouter } from "next/compat/router";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import * as amplitude from "@amplitude/analytics-browser";



export default function Login() {
  

  const { data: env } = useGetEnvListQuery({keys: ["NEXTAUTH_SERVICE_ID", "ACCOUNT_URL","NEXTAUTH_URL"]} ,{
    pollingInterval: 30 * 60 * 1000,
    refetchOnFocus: false,
    refetchOnMountOrArgChange: false,
    refetchOnReconnect: false,
    skip: false,
  });

  
  const [cookies] = useCookies(["isSignOut"]);
  const router = useRouter();
  const { redirect = "" } = router?.query;
  const [isFromHomeState, setIsFromHomeState] = useState(false);
  const [redirectPath, setRedirectPath] = useState(redirect || "/dashboard");
  const session = useSession();
  const { openCheckout, home } = router?.query;
  const dispatch = useAppDispatch();
  const accountUserInfo = useAppSelector(
    (store) => getDashBoardState(store).accountUserInfo
  );
  const [trigger, { data: accountUserInfoData }] =
    dashboardApi.endpoints.getAccountUserInfo.useLazyQuery();

  const queryString = new URLSearchParams(router.query).toString();

  const { data: NEXTAUTH_URL } = useGetEnvQuery(
    { key: "NEXTAUTH_URL" },
    {
      pollingInterval: 30 * 60 * 1000,
      refetchOnFocus: false,
      refetchOnMountOrArgChange: false,
      refetchOnReconnect: false,
      skip: false,
    }
  );

  const onClickLogo = () => {
    router?.push("https://www.deepbrain.io/");
  };

  const routerPushFunc = (url: string) => {
    router?.push(url);
  };

  useEffect(() => {
    if (router && !router.isReady) return;
    else if (router && router.isReady && home === "true") {
      setIsFromHomeState(true);
    }
  }, [home, router, router.isReady]);

  useEffect(() => {
    let abTestDashboard = "a_dashboard";
    if (openCheckout) {
      setRedirectPath(`/dashboard?${queryString}`); // a
    } else {
      setRedirectPath(redirect);
    }
    // homepage에서 로그인 버튼으로 들어온경우 이벤트 제외
    if (home !== "true") {
      window.gtag("set", "user_properties", {
        ab_test_dashboard_240423: abTestDashboard,
      });
    }
  }, [home, openCheckout, queryString, redirect]);

  
  
  const handleSignInCallback = useCallback(() => {
    router?.push(redirectPath);
  }, [redirectPath, router]);

  useEffect(() => {
    async function initCheckAccountUserInfo() {
      try {
        
        trigger();
        if (accountUserInfoData) {
          dispatch(
            dashboardActions.setAccountUserInfo({
              accountUserInfo: accountUserInfoData,
            })
          );
          if (
            accountUserInfoData?.plan
              ?.toLocaleLowerCase()
              ?.includes("enterprise")
          ) {
            if (!accountUserInfoData?.enterprise) {
              await checkEnterpriseCompany({
                userId: session?.data?.user?.id,
                NEXTAUTH_URL,
              })
                .then((result) => {
                  if (result?.success) {
                    dispatch(
                      dashboardActions.setAccountUserInfo({
                        accountUserInfo: {
                          ...accountUserInfoData,
                          enterprise: true,
                        },
                      })
                    );
                  }
                })
                .catch((error) => {
                  console.error(
                    `LoginModal initCheckAccountUserInfo checkEnterpriseCompany error `,
                    { error }
                  );
                });
            }
          }

          if (
            accountUserInfoData?.plan?.toLocaleLowerCase()?.includes("team") ||
            accountUserInfoData?.plan?.toLocaleLowerCase()?.includes("pro") ||
            accountUserInfoData?.plan
              ?.toLocaleLowerCase()
              ?.includes("enterprise") ||
            accountUserInfoData?.enterprise
          ) {
            
            dispatch(
              dashboardActions.setWorkspaceList({
                workspaceListData: accountUserInfoData?.workspaceList,
                total: accountUserInfoData?.workspaceListTotal,
                inviteWorkspaceLogs: accountUserInfoData?.inviteWorkspaceLogs,
              })
            );
          }
        }
      } catch (error) {
        console.error(`LoginModal initCheckAccountUserInfo error `, { error });
      }
    }

    if (session.status === "authenticated") {
      dispatch(projectActions.updateProjectUserId(session.data.user.email));
      if (accountUserInfo?._id === "") {
        initCheckAccountUserInfo();
      }
    }
  }, [session, dispatch, accountUserInfo, accountUserInfoData, NEXTAUTH_URL]);

  useEffect(() => {
    if (!router || !router.isReady) return;

    const callOAuthCallback = sessionStorage.getItem("callOAuthCallback");
    const isCallback = callOAuthCallback === "true";

    const fromGoogle = router.query.from === "google";
    const fromAzure = router.query.from === "microsoft";
    const loggedIn = session.status === "authenticated";
    const callbackCondition =
      (fromGoogle || fromAzure) && isCallback && loggedIn;
    if (callbackCondition) handleSignInCallback();
  }, [handleSignInCallback, redirectPath, router, session.status]);

  useEffect(() => {
    
    if (session.status === "authenticated") {
      const identifyEvent = new amplitude.Identify();
      const isPaid = accountUserInfoData?.plan !=='none' ? "paid" : "non_paid"

      window.gtag('set', 'user_properties', {
        "subscription_status": isPaid
      });

      identifyEvent.set("subscription_status", accountUserInfoData?.plan !=='none' ? "paid" : "non_paid");
      amplitude.identify(identifyEvent);
    }
  }, [accountUserInfoData?.plan, session?.data?.user?.id, session.status]);

  return (
    <div>
      <AuthTemplate
        onClickLogo={onClickLogo}
        signInCallback={handleSignInCallback}
        redirectPath={redirectPath}
        accountUrl={env?.ACCOUNT_URL!}
        studioUrl={env?.NEXTAUTH_URL!}
        routerPushFunc={routerPushFunc}
        isLoginDefault={isFromHomeState || cookies?.isSignOut}
        landingPath={""}
        serviceId={env?.NEXTAUTH_SERVICE_ID!}
      />
    </div>
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const session = await getServerSession(
    context.req,
    context.res,
    nextAuthOptions
  );
  const { BK_ACCOUNT_URL, BK_STUDIO_URL, NEXTAUTH_URL } = runtimeEnv;
  const query = context.query;
  const {
    step = "",
    plan = "",
    period = "",
    openCheckout = "",
    enterpriseParams,
    redirect = "/dashboard",
  } = query;
  const userId = session?.user?.id || "";

  if (enterpriseParams) {
    const { data: enterpriseDecryptParams } = await axios.post(
      `${BK_STUDIO_URL}/api/workspace/decrypt-params`,
      {
        params: enterpriseParams,
        key: "enterprisePlan",
      }
    );
    const decryptParams = enterpriseDecryptParams?.data?.decryptParams;

    if (decryptParams?.length > 0) {
      const payer = decryptParams.find((obj) => obj.payer)?.payer;
      const pricingId = decryptParams.find((obj) => obj.pricingId)?.pricingId;
      const quantity = decryptParams.find((obj) => obj.quantity)?.quantity;
      const coupon = decryptParams.find((obj) => obj?.coupon)?.coupon ?? null;

      if (userId === payer) {
        const cancelUrl = NEXTAUTH_URL;
        const successUrl = `${NEXTAUTH_URL}/checkout_redirect?session_id={CHECKOUT_SESSION_ID}&redirect=${NEXTAUTH_URL}`;
        const apiUrl = `${BK_ACCOUNT_URL}/api/studio/v3/subscriptions`;

        const subscriptionRes = await axios.post(apiUrl, {
          userId,
          pricingId,
          success_url: successUrl,
          cancel_url: cancelUrl,
          quantity,
          coupon,
        });

        if (subscriptionRes.data.success) {
          return {
            redirect: {
              destination: subscriptionRes.data.data,
              permanent: false,
            },
          };
        } 
      }
    }
  }

  if (step && plan && period && openCheckout && userId) {
    const userSubscriptionType = await checkUserSubscriptionType({
      userId,
      step,
      plan,
      period,
    });



    await axios.post(`${BK_STUDIO_URL}/api/user/referral`, {});

    const getPlanUrl = `${BK_ACCOUNT_URL}/api/plans?product=studio3.0`;
    const { data } = await axios.get(getPlanUrl);

    const products = data
      ?.filter(({ planName }: { planName: string }) =>
        planName.toLowerCase().includes(plan)
      )
      .sort((a: PlanType, b: PlanType) => a.order - b.order);

    const periodKey: string = period;
    const pricingId = products[+step].planId[periodKey];
    const quantity = products[+step].quantity;

    const cancelUrl = NEXTAUTH_URL;
    const successUrl = `${NEXTAUTH_URL}/checkout_redirect?session_id={CHECKOUT_SESSION_ID}&redirect=${NEXTAUTH_URL}`;

    if (userSubscriptionType === "start") {
      const apiUrl = `${BK_ACCOUNT_URL}/api/studio/v3/subscriptions`;
      const subscriptionRes = await axios.post(apiUrl, {
        userId,
        pricingId,
        success_url: successUrl,
        cancel_url: cancelUrl,
        quantity,
      });

      if (subscriptionRes.data.success) {
        return {
          redirect: {
            destination: subscriptionRes.data.data,
            permanent: false,
          },
        };
      }
    } else if (userSubscriptionType === "upgrade") {
      try {
        const endPoint = `${BK_ACCOUNT_URL}/api/studio/v3/subscriptions/upgrade`;

        const subscriptionRes = await axios.post(endPoint, {
          userId,
          pricingId,
          quantity,
          success_url: successUrl,
        });

        if (subscriptionRes.data.success) {
          return {
            redirect: {
              destination: subscriptionRes.data.data,
              permanent: false,
            },
          };
        } 
      } catch (error) {
        console.log("error: ", error);
      }
    }
  }

  if (session) {
    return { redirect: { destination: redirect } };
  }
  return { props: {} };
}
