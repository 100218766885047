import axios from "axios";
import { runtimeEnv } from "./runtime-utils";
import { String } from "aws-sdk/clients/codebuild";
import CryptoJS from "crypto-js";

const TAG = '[Workspace-Util]'

export async function handleCreateWorkspace({
  toEmail = [],
  workspaceName = '',
  fromEmail = '',
  templateName = '',
  companyId = '',
  NEXTAUTH_URL = '',
  workspaceId = '',
}: {
  toEmail: string[],
  workspaceName: string,
  fromEmail: string,
  templateName: string,
  companyId: string,
  NEXTAUTH_URL: string,
  workspaceId: string,
}) {
  try {
    console.log(TAG, `handleCreateWorkspace params `, {toEmail}, {workspaceName}, {fromEmail}, {templateName}, {companyId}, {NEXTAUTH_URL})
    // throw new Error(`Can not make ${workspaceName} workspace. Please try again`);
    /**
     * 1. workspace먼저 만들어줌
     */
    const { data: workspaceResult } = await axios.post(`${NEXTAUTH_URL}/api/workspace/create-workspace`, {
      workspaceName,
      fromEmail,
      companyId,
      workspaceId
    });

    // console.log(TAG, `handleCreateWorkspace workspaceResult `, { workspaceResult });

    // if(!workspaceResult?.data?.data?.newWorkspaceId) {
    //   throw new Error(`Can not make ${workspaceName} workspaces. Please try again`);
    // }

    for(const email of toEmail) {
      const { data: sendEmailResult } = await axios.post(`${NEXTAUTH_URL}/api/workspace/send-email`, {
        email,
        workspaceName,
        fromEmail,
        templateName,
        workspaceId,
        companyId
      });

      console.log(TAG, `handleCreateWorkspace sendEmailResult `, {sendEmailResult});
      if(!sendEmailResult?.success) {
        throw new Error(`Can not send email. Please try again.`);
      }
    }
    
    return {
      // newWorkspaceId: `65fa9f12e78792ab6f119afd`,
      newWorkspaceId: workspaceId,
      newWorkspace: null
    }
    // return {
    //   newWorkspaceId: workspaceResult?.data?.data?.newWorkspaceId,
    //   newWorkspace: workspaceResult?.data?.data?.newWorkspace,
    // }
    
  } catch(error) {
    console.log(TAG, `handleCreateWorkspace error : ${error}`);
    throw new Error (`handleCreateWorkspace error : ${error}`);
  }
}

export async function handleInviteMember({
  toEmail = [],
  companyId = '',
  workspaceId = '',
  workspaceName = '',
  fromEmail = '',
  templateName = 'inviteWorkspace',
  NEXTAUTH_URL
}: {
  toEmail: string[],
  companyId: string,
  workspaceId: string,
  workspaceName: string,
  fromEmail: string,
  templateName: string,
  NEXTAUTH_URL: string
}) {
  if(workspaceId === '') {
    console.error(`No workspaceId to invite workspace member`);
    throw new Error (`No workspaceId to invite workspace member`);
  }

  try {
    console.log(TAG, `handleInviteMember params `, {toEmail}, {workspaceId}, {workspaceName} ,{fromEmail}, {templateName}, {NEXTAUTH_URL});
    for(const email of toEmail) {
      const { data: sendEmailResult } = await axios.post(`${NEXTAUTH_URL}/api/workspace/send-email`, {
        email,
        workspaceName,
        fromEmail,
        templateName,
        workspaceId,
        companyId,
      });

      console.log(TAG, `handleInviteMember sendEmailResult `, {sendEmailResult});

      if(!sendEmailResult?.success) {
        throw new Error(`Can not send email. Please try again.`);
      }
    }

    return {
      newWorkspaceId: workspaceId,
      newWorkspace: null,
    }
  } catch(error) {
    console.log(TAG, `handleInviteMember error `, {error});
    throw new Error(`Can't invite member in workspace: ${error}`);
  }

}

export async function joinWorkspaceMember({
  decryptParams,
  userId,
  NEXTAUTH_URL
}: {
  decryptParams: {},
  userId: string,
  NEXTAUTH_URL: string,
}) {
  try {
    console.log(TAG, `joinWorkspaceMember param : `, {decryptParams}, {userId}, {NEXTAUTH_URL});
    const { data: joinWorkspaceMemberResult } = await axios.post(`${NEXTAUTH_URL}/api/workspace/join-workspace-member`, {
      ...decryptParams,
    });
    console.log(TAG, `joinWorkspaceMember joinWorkspaceMemberResult `, {joinWorkspaceMemberResult})

    return { joinWorkspaceMemberResult }

  } catch(error) {
    console.log(TAG, `joinWorkspaceMember error : ${error}`);
    throw new Error (`joinWorkspaceMember error : ${error}`);
  }
}

export async function updateUserWorkspacePermission({
  workspaceId,
  companyId,
  changePermission,
  updatedBy,
  NEXTAUTH_URL
}: {
  workspaceId: string;
  companyId: string;
  changePermission: UserWorkspacePermission[];
  updatedBy: string;
  NEXTAUTH_URL: string;
}) {
  try {
    console.log(TAG, `updateUserWorkspacePermission params `, {workspaceId}, {companyId}, {changePermission}, {updatedBy}, {NEXTAUTH_URL})
    const { data: updateUserWorkspacePermissionResult } = await axios.post(`${NEXTAUTH_URL}/api/workspace/update-workspace-member`, {
      workspaceId,
      companyId,
      changePermission,
      updatedBy
    });
    console.log(TAG, `updateUserWorkspacePermission error `, { updateUserWorkspacePermissionResult })
    return updateUserWorkspacePermissionResult;
  } catch(error) {
    console.log(TAG, `updateUserWorkspacePermission error `, {error});
    throw new Error(`updateUserWorkspacePermission error : ${error}`);
  }
}

export async function initCheckProjectShareMember({
  userId,
  projectId,
  workspaceId, 
  companyId, 
  NEXTAUTH_URL
}: {
  userId: string;
  projectId: string;
  workspaceId: string;
  companyId: string;
  NEXTAUTH_URL: string;
}) {
  try {
    console.log(TAG, `initCheckProjectShareMember params: `, {userId}, {projectId}, {NEXTAUTH_URL}, {workspaceId}, {companyId});
    const { data: initCheckProjectShareMemberResult } = await axios.post(`${NEXTAUTH_URL}/api/workspace/check-project-shared-member`, {
      userId,
      projectId,
      workspaceId,
      companyId
    });
    console.log(TAG, `initCheckProjectShareMember result `, {initCheckProjectShareMemberResult});
    return initCheckProjectShareMemberResult;

  } catch(error) {
    console.error(TAG, `initCheckProjectShareMember error `, {error});
    throw new Error(`initCheckProjectShareMember error : ${error}`);
  }
}

export async function setProjectSharePermission({
  projectId,
  workspaceId,
  companyId,
  NEXTAUTH_URL,
  userId,
  members,
}: {
  projectId: string;
  workspaceId: string;
  companyId: string;
  NEXTAUTH_URL: string;
  userId: string;
  members: UserWorkSpaceInfoSubType[],
}) {
  try {
    console.log(TAG, `setProjectSharePermission params `, {projectId}, {workspaceId}, {companyId}, {userId}, {members});
    const { data: projectSharePermissionResult } = await axios.post(`${NEXTAUTH_URL}/api/workspace/set-project-share-permission`, {
      projectId,
      workspaceId,
      companyId,
      userId,
      members,
    });
    console.log(TAG, `setProjectSharePermission result `, {projectSharePermissionResult});
    return projectSharePermissionResult;

  } catch(error) {
    console.error(TAG, `setProjectSharePermission error `, {error});
    throw new Error(`setProjectSharePermission error : ${error}`);
  }
}

export async function setSaveProjectSharePermission({
  projectId,
  workspaceId, 
  companyId,
  NEXTAUTH_URL,
  userId,
  members,
  isPublic = false,
}: {
  projectId: string;
  workspaceId: string;
  companyId: string;
  NEXTAUTH_URL: string;
  userId: string;
  members: UserProjectShareType[],
  isPublic: boolean;
}) {
 try {
  console.log(TAG, `setSveProjectSharePermission params `, {projectId}, {workspaceId}, {companyId}, {NEXTAUTH_URL}, {userId}, {members});
  const { data: saveProjectSharePermissionResult } = await axios.post(`${NEXTAUTH_URL}/api/workspace/save-project-share-permission`, {
    projectId,
    workspaceId, 
    companyId,
    NEXTAUTH_URL,
    userId,
    members,
    isPublic
  });
  console.log(TAG, `setSaveProjectSharePermission result `, {saveProjectSharePermissionResult});
  return saveProjectSharePermissionResult;
 } catch(error) {
  console.error(TAG, `setSaveProjectSharePermission error `, {error});
  throw new Error(`setSaveProjectSharePermission error : ${error}`);
 } 
}

export async function checkEnterpriseCompany({
  userId = '',
  NEXTAUTH_URL = '',
} : {
  userId: string;
  NEXTAUTH_URL: string;
}) {
  if(!userId) {
    console.error(`No userId to checkEnterpriseCompany ${userId}`);
    throw new Error(`No userId to checkEnterpriseCompany ${userId}`);
  }

  console.log(TAG, `checkEnterpriseCompany params `, {userId});
  try {
    const { data: enterpriseCompanyInfo } = await axios.get(`${NEXTAUTH_URL}/api/workspace/enterprise-company?userId=${userId}`);
    console.log(TAG, `checkEnterpriseCompany `, {enterpriseCompanyInfo});
    return enterpriseCompanyInfo;

  } catch(error) {
    console.error(TAG, `checkEnterpriseCompany error `, {error});
    throw new Error(`checkEnterpriseCompany error : ${error}`);
  }
}

export async function checkWorkspaceInfo({
  userId = '',
  NEXTAUTH_URL = '',
}: {
  userId: string;
  NEXTAUTH_URL: string;
}) {
  if(!userId) {
    console.error(`No userId to checking WorkspaceInfo ${userId}`);
    throw new Error(`No userId to checking WorkspaceInfo ${userId}`);
  }

  console.log(TAG, `checkWorkspaceInfo params `, {userId}, {NEXTAUTH_URL});
  try {
    const { data: workspaceInfo } = await axios.get(`${NEXTAUTH_URL}/api/workspace/workspace-info?userId=${userId}`);
    console.log(TAG, `checkWorkspaceInfo result `, {workspaceInfo});
    return workspaceInfo;
  } catch(error) {
    console.error(TAG, `checkWorkspaceInfo error `, {error});
    throw new Error(`checkWorkspaceInfo error : ${error}`);
  }
}

export async function setProPlanWorkspace({
  userId = '',
  workspaceId = '',
  companyId = '',
  NEXTAUTH_URL = '',
  userName = '',
}: {
  userId: string;
  workspaceId: string;
  companyId: string;
  NEXTAUTH_URL: string;
  userName: string;
}) {
  if(!workspaceId) {
    console.error(TAG, `setProPlanWorkspace no workspaceId `, {workspaceId});
    throw new Error(`setProPlanWorkspace no workspaceId`);
  }

  console.log(TAG, `setProPlanWorkspace params `, {userId}, {workspaceId}, {companyId}, {NEXTAUTH_URL}, {userName});
  try {
    const { data: proPlanWorkspaceInfo } = await axios.post(`${NEXTAUTH_URL}/api/workspace/pro-plan-workspace`, {
      userId,
      workspaceId,
      companyId,
      userName
    });
    console.log(TAG, `setProPlanWorkspace result `, {proPlanWorkspaceInfo});
    return proPlanWorkspaceInfo;
  } catch(error) {
    console.error(TAG, `setProPlanWorkspace error `, {error});
    throw new Error(`setProPlanWorkspace error ${error}`);
  }
}

/**
 * 3.2.2 고도화부터 들어가는 부분 
 */
export async function getWorkspaceMember({
  workspaceId,
  workspaceUserList,
}: {
  workspaceId: string;
  workspaceUserList: string[]
}) {
  if(!workspaceId) {
    console.error(TAG, `getWorkspaceMember no workspaceId `, {workspaceId});
    throw new Error(`getWorkspaceMember no workspaceId`);
  }

  try {
    const { data: workspaceMemberInfo } = await axios.post(`/api/workspace/getWorkspaceMember`, {
      workspaceId,
      workspaceUserList
    });

    return workspaceMemberInfo;

  } catch(error) {
    console.error(TAG, `getWorkspaceMember error `, {error});
    throw new Error(`getWorkspaceMember error ${error}`);
  }
}

type ChangeUserType = {
  userId: string;
  userType: string;
  workspaceId: string;
}

export async function updateWorkspaceUserType(changeUserType: ChangeUserType[]) {
  try {
    const { data: updateWorkspaceUserTypeResult } = await axios.post(`/api/workspace/updateWorkspaceUserType`, {
      changeUserType
    });

    return updateWorkspaceUserTypeResult;

  } catch(error) {
    console.error(TAG, `updateWorkspaceUserType error `, {error});
    throw new Error(`updateWorkspaceUserType error ${error}`);
  }
}
type MemberType = {
  email: string;
  userType: string;
}

export async function checkIsExistSendInvitation({
  inviteMembers = [],
  workspaceId = '',
}: {
  inviteMembers: MemberType[],
  workspaceId: string,
}) {
  try {
    const params = new URLSearchParams();
    inviteMembers.forEach((members, index) => {
      params.append(`members[${index}][email]`, members.email);
      params.append(`members[${index}][userType]`, members.userType);
    });
    params.append('workspaceId', workspaceId);

    const { data: isExistSendInvitation} = await axios.get(`/api/workspace/renewal/checkIsExistSendInvitation?${new URLSearchParams(params)}`)
    return isExistSendInvitation;
  } catch(error) {
    console.log(TAG, `checkIsExistSendInvitation error `, {error});
    throw new Error(`checkIsExistSendInvitation: ${error}`);
  }
}

export async function checkIsExistMember({
  inviteMembers = [],
  workspaceId = ''
}: {
  inviteMembers: MemberType[],
  workspaceId: string,
}) {
  try {
    const params = new URLSearchParams();
    inviteMembers.forEach((members, index) => {
      params.append(`members[${index}][email]`, members.email);
      params.append(`members[${index}][userType]`, members.userType);
    });
    params.append('workspaceId', workspaceId);


    const { data: isExistMember} = await axios.get(`/api/workspace/renewal/checkIsExistMember?${new URLSearchParams(params)}`)
    return isExistMember;
    
  } catch(error) {
    console.log(TAG, `inviteMembers error `, {error});
    throw new Error(`inviteMembers error ${error}`);
  }
}

export async function handleInviteMemberRenewal({
  inviteMembers = [],
  templateName = 'inviteWorkspace',
  companyId = '',
  workspaceId = '',
  workspaceName = '',
  invitedBy = '',
}: {
  inviteMembers: MemberType[],
  companyId: string,
  workspaceId: string,
  workspaceName: string,
  invitedBy: string,
  templateName: string,
}) {
  try {
    for await (const members of inviteMembers) {
      const { data: sendEmailResult } = await axios.post(`/api/workspace/send-email-renewal`, {
        email: members.email,
        userType: members.userType,
        invitedBy,
        templateName,
        workspaceId,
        companyId,
        workspaceName,
      });

      if(!sendEmailResult?.success) {
        throw new Error(`Can not send email. Please try again.`);
      }
    }
    return { success: true }

  } catch(error) {
    console.log(TAG, `handleInviteMember error `, {error});
    throw new Error(`Can't invite member in workspace: ${error}`);
  }
}

export async function handleRequestMemberWorkspace({
  inviteMembers = { email: '', userType: 'member'},
  templateName = 'inviteWorkspaceMember',
  companyId = '',
  workspaceId = '',
  workspaceName = '',
  invitedBy = '',
}: {
  inviteMembers: MemberType,
  companyId: string,
  workspaceId: string,
  workspaceName: string,
  invitedBy: string,
  templateName: string,
}) {
  try {
    const { data: requestMemberWorkspaceResult } = await axios.post(`/api/workspace/renewal/requestMemberWorkspace`, {
      inviteMembers,
      templateName,
      companyId,
      workspaceId,
      workspaceName,
      invitedBy
    });

    return requestMemberWorkspaceResult;
  } catch(error) {
    console.log(TAG, `handleRequestMemberWorkspace error `, {error});
    throw new Error(`Can't request member in workspace : ${error}`);
  }
}

/**
 * master, admin이 request email 보낸거 있는지 확인함 
 */
export async function checkRequestMemberWorkspace({
  workspaceId = ''
}: {
  workspaceId: string
}) {
  try {
    const { data: checkRequestMemberWorkspaceResult } = await axios.get(`/api/workspace/renewal/checkRequestMemberWorkspace?workspaceId=${workspaceId}`);
    return checkRequestMemberWorkspaceResult;

  } catch(error) {
    console.log(TAG, `handleRequestMemberWorkspace error `, {error});
    throw new Error(`Can't check request member ${workspaceId} workspace: ${error}`);
  }
}

/**
 * admin, member request email for upgrade plan
 */
export async function requestUpgradePlanToMaster({
  workspaceId = '',
  email = '',
  companyId = '',
  templateName = '',
  workspaceName = '',
  toEmail = '',
}: {
  workspaceId : string,
  email: string,
  companyId: string,
  templateName: string,
  workspaceName: string,
  toEmail: string,
}) {
  try {
    const { data: requestUpgradePlanToMasterResult } = await axios.post(`/api/workspace/renewal/requestUpgradePlanToMaster`, {
      workspaceId,
      email,
      companyId,
      templateName,
      workspaceName,
      toEmail
    });

    return requestUpgradePlanToMasterResult;

  } catch(error) {
    console.log(TAG, `requestUpgradePlanToMaster error `, {error});
    throw new Error(`Can't request upgrade plan to master`); 
  }
}

/**
 * decryptParams
 */
export async function decryptWorkspaceParams({
  params = '',
  key = ''
}: {
  params: string | string[] | undefined,
  key: string
}) {
  try {
    const { data: decryptWorkspaceParamsResult } = await axios.post(`/api/workspace/decrypt-params`, {
      key, 
      params
    });

    return decryptWorkspaceParamsResult;

  } catch(error) {
    console.error(TAG, `decryptWorkspaceParams error `, {error});
    throw new Error(`Can't decrypt workspace params for upgrading`);
  }
}

export async function joinInviteWorkspace({
  workspaceId = '',
  email = '',
  fromEmail = '',
  companyId = '',
  type = '',
  selectJoinWorkspace = [],
  plan = '',
}: {
  workspaceId: string,
  email: string,
  fromEmail: string,
  companyId: string,
  type: string,
  selectJoinWorkspace?: SelectJoinWorkspace[],
  plan?: string
}) {
  try {
    const { data: joinInviteWorkspaceResult } = await axios.post(`/api/workspace/renewal/joinInviteWorkspace`, {
      workspaceId,
      email,
      fromEmail,
      companyId,
      type,
      selectJoinWorkspace, 
      plan
    });
    return joinInviteWorkspaceResult

  } catch(error) {
    console.error(TAG, `joinInviteWorkspace error `, {error});
    throw new Error(`Can't join invite workspace`);
  }
}

export async function decryptParams({
  params = '',
  key = ''
}: {
  params: string | string[] | undefined,
  key: string
}){
  const bytes = CryptoJS.AES.decrypt(params, key);
  const decryptData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptData;
}

export async function createWorkspace({
  companyId = '',
  workspaceName = '',
  userId = ''
}: {
  companyId: string,
  workspaceName: string,
  userId: string
}) {
  try {
    const { data: createWorkspaceResult } = await axios.post(`/api/workspace/renewal/createWorkspace`, {
      companyId,
      workspaceName,
      userId
    });

    return createWorkspaceResult;
  } catch(error) {
    console.error(TAG, `createWorkspace error `, error);
    throw new Error(`Can't create workspace.`);
  }
}

export async function checkIsExistSubscription({
  inviteMembers = [],
}: {
  inviteMembers: MemberType[],
}) {
  try {
    const params = new URLSearchParams();
    inviteMembers.forEach((members, index) => {
      params.append(`members[${index}][email]`, members.email);
      params.append(`members[${index}][userType]`, members.userType);
    });

    const { data: isExistSubscription } = await axios.get(`/api/workspace/renewal/checkIsExistSubscription?${new URLSearchParams(params)}`);
    return isExistSubscription;
  } catch(error) {
    console.error(TAG, `checkIsExistSubscription error `, error);
    throw new Error(`Can't check subscription`);
  }
}

export async function getWorkspaceList({
  companyId = '',
  userId = '',
}: {
  companyId: string,
  userId: string,
}) {
  try {
    const { data: workspaceList } = await axios.post(`/api/workspace/renewal/getWorkspaceList`, {
      companyId,
      userId
    });

    return workspaceList;
  } catch(error) {
    console.error(TAG, `getWorkspaceList error `, error);
    throw new Error('Can get Workspace List');
  }
}

export async function updateWorkspaceInfo({
  companyId = '',
  workspaceId = '',
  updateParams = {}
}: {
  companyId: string,
  workspaceId: string,
  updateParams: {}
}) {
  try {
    const { data: updateWorkspaceInfoResult } = await axios.post(`/api/workspace/renewal/updateWorkspaceInfo`, {
      companyId,
      workspaceId,
      updateParams
    });
    
    return updateWorkspaceInfoResult;

  } catch(error) {
    console.error(TAG, `updateWorkspaceInfo error `, error);
    throw new Error(`Update Workspace Info has error`);
  }
}

export async function inviteCopyLinkWorkspace({
  workspaceId = '',
  userId = '',
  companyId = '',
  invitedBy = '',
}: {
  workspaceId: string,
  userId: string,
  companyId: string,
  invitedBy: string,
}) {
  try {
    const { data: inviteCopyLinkWorkspaceResult } = await axios.post(`/api/workspace/renewal/inviteCopyLinkWorkspace`, {
      workspaceId,
      userId,
      companyId,
      invitedBy,
    });

    return inviteCopyLinkWorkspaceResult;

  } catch(error) {
    console.error(TAG, `inviteCopyLinkJoinWorkspace error `, error);
    throw new Error(`Invite Copy Link Join Workspace has error`);
  }
}

// export async function handleRequestPermissionRenewal({
//   templateName = 'requestProjectCommentPermission',
//   projectId="",
//   userId="",
//   sharePermission = "comment",
//   shareType = "user",
// }: {
//   templateName: string;
//   projectId: string;
//   userId: string;
//   sharePermission: string;
//   shareType: string;
// }) {
//   try {
    
//     // const mongoClient = await connectToMasterDB();
//     // const db = await mongoClient.db.collection('user')

//     const { BK_ACCOUNT_URL } = runtimeEnv;
//     const reuslt = await axios.post(`${runtimeEnv}/`)


//       if(!sendEmailResult?.success) {
//         throw new Error(`Can not send email. Please try again.`);
//       }
    
//     return { success: true }

//   } catch(error) {
//     console.log(TAG, `handleInviteMember error `, {error});
//     throw new Error(`Can't invite member in workspace: ${error}`);
//   }
// }
